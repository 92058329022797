import React, {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";

const MobileContext = createContext({
    isMobile: false
})

export const MobileProvider: FC<PropsWithChildren> = ({children}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <MobileContext.Provider value={{isMobile}}>
        {children}
    </MobileContext.Provider>
}

export const useMobile = (): { isMobile: boolean } => {
    const {isMobile} = useContext(MobileContext)

    return {isMobile}
}